


































































































































































































.canvas-wrapper {
  top: 0;
  left: 0;
}
.hero-logo {
  width: calc(100% - 50px);
  /deep/ svg {
    width: 100%;
  }
}
.hero-liquid {
  width: 100%;
  /deep/ svg {
    width: 100%;
  }
}
